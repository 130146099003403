import { useMemo, useState } from "react";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { Row, Col, Image } from "react-bootstrap";
import CustomMarker from "../../assets/svg/dot-circle.svg";

const DetailMap = (props: any) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (markerId: any) => {
    if (markerId === activeMarker) {
      return;
    }
    setActiveMarker(markerId);
  };

  const containerStyle = {
    width: "100%",
    height: "450px",
  };
  const center = useMemo(() => ({ lat: props.lat, lng: props.lng }), []);

  return (
    <div>
      <GoogleMap
        zoom={props.zoom ? props.zoom : 10}
        center={center}
        mapContainerStyle={containerStyle}
        onClick={() => setActiveMarker(null)}
      >
        <Marker
          onClick={() => {
            handleActiveMarker(props.markerId);
          }}
          position={{ lat: props.lat, lng: props.lng }}
          options={{
            icon: CustomMarker,
          }}
        >
          {activeMarker === props.markerId ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <Row className="justify-content-between overflow-hidden">
                  <Col md={4}>
                    <Image
                      src={props.image}
                      style={{
                        width: "80px",
                        height: "-webkit-fill-available",
                      }}
                      alt=""
                    />
                  </Col>
                  <Col md={8}>
                    <h6 className="primary-color">{props.name}</h6>

                    <span>{props.address}</span>
                  </Col>
                </Row>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>
      </GoogleMap>
    </div>
  );
};

export default DetailMap;
