import React, { useEffect, useState, useRef } from "react";
import {
  useAppSelector,
  useAppDispatch,
  useScreenWidth,
} from "../../app/hooks";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Table,
  Form,
  Modal,
  FormControl,
  Image,
} from "react-bootstrap";
import { MdOutlineArrowUpward, MdStar } from "react-icons/md";
import { GrLocationPin } from "react-icons/gr";
import styles from "./PropertyDetails.module.css";
import { Carousel } from "react-responsive-carousel";
import { DayPicker } from "react-day-picker";
import AirConditioner from "../../assets/images/Icons/air-conditioner.svg";
import AlarmBell from "../../assets/images/Icons/alarm-bell.svg";
import Balcony from "../../assets/images/Icons/balcony.svg";
import BookShelf from "../../assets/images/Icons/book-shelf.svg";
import ComputerTable from "../../assets/images/Icons/computer-table.svg";
import Cupboard from "../../assets/images/Icons/cupboard.svg";
import DiningArea from "../../assets/images/Icons/dining-area.svg";
import Dishwasher from "../../assets/images/Icons/dishwasher.svg";
import ElectricKettle from "../../assets/images/Icons/electric-kettle.svg";
import Elevator from "../../assets/images/Icons/elevator.svg";
import FirstAid from "../../assets/images/Icons/first-aid.svg";
import HairDryer from "../../assets/images/Icons/hair-dryer.svg";
import Heater from "../../assets/images/Icons/Heater.svg";
import Hottub from "../../assets/images/Icons/hottub.svg";
import HouseCleaning from "../../assets/images/Icons/house-cleaning.svg";
import IronBoard from "../../assets/images/Icons/iron-board.svg";
import Iron from "../../assets/images/Icons/iron.svg";
import Laundry from "../../assets/images/Icons/laundry.svg";
import Microwave from "../../assets/images/Icons/microwave.svg";
import Monitor from "../../assets/images/Icons/monitor.svg";
import NoSmoking from "../../assets/images/Icons/no-smoking.svg";
import Parking from "../../assets/images/Icons/parking.svg";
import PetFriendly from "../../assets/images/Icons/pet-friendly.svg";
import PetNotAllowed from "../../assets/images/Icons/pets-not-allowed.svg";
import Refridgerator from "../../assets/images/Icons/refridgerator.svg";
import Security from "../../assets/images/Icons/security.svg";
import SharedGym from "../../assets/images/Icons/shared-gym.svg";
import SmokingDetector from "../../assets/images/Icons/smoking-detector.svg";
import Sofa from "../../assets/images/Icons/sofa.svg";
import Storage from "../../assets/images/Icons/storage.svg";
import StoveOven from "../../assets/images/Icons/stove-oven.svg";
import Stove from "../../assets/images/Icons/stove.svg";
import SwimmingPool from "../../assets/images/Icons/swimming-pool.svg";
import Utensils from "../../assets/images/Icons/utensils.svg";
import Wifi from "../../assets/images/Icons/wifi.svg";
import MapImg from "../../assets/images/mapimi.png";
import { FiSend } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { MdOutlineContentCopy } from "react-icons/md";
import { getPropertyDetailsAsync } from "./PropertyDetailsSlice";
import DetailMap from "./DetailMap";
import { useJsApiLoader } from "@react-google-maps/api";
import { Anchor } from "antd";
import moment from "moment";
import ImageModal from "./ImageModal";
const { Link } = Anchor;

const PropertyDetails = () => {
  const { propertyId } = useParams();
  const dispatch = useAppDispatch();
  const screenWidth = useScreenWidth();
  // eslint-disable-next-line
  const [targetOffset, setTargetOffset] = useState<number>(1);
  useEffect(() => {
    // setTargetOffset((6 * window.innerHeight) / 7);
  }, []);
  useEffect(() => {
    dispatch(getPropertyDetailsAsync(propertyId ? propertyId : ""));
    // eslint-disable-next-line
  }, []);

  const propertyDetails = useAppSelector(
    (state) => state.propertyDetails.propertyDetails
  );

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showEnquiry, setShowEnquiry] = useState(false);

  const handlePhoneClose = () => setShowPhoneNumber(false);
  const handleEnquiryClose = () => setShowEnquiry(false);

  const [showMap, setShowMap] = useState(false);

  const handleMapShow = () => setShowMap(true);
  const handleMapClose = () => setShowMap(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const [readMore, setReadMore] = useState(false);

  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);

  const today = new Date();
  function convertDate(dateString: string) {
    const [month, day, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  }

  const PropAvailableDate =
    propertyDetails && propertyDetails?.PropertyAvailabilityDate
      ? convertDate(propertyDetails?.PropertyAvailabilityDate)
      : today;
  const [imgModal, setImgModal] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
  };
  const customPrevArrow = (clickHandler: () => void) => (
    <div
      className={styles["customPrevArrow"]}
      style={{
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        width: 30,
        height: 30,
        cursor: "pointer",
        display: currentSlide === 0 ? "none" : "block",
      }}
      onClick={clickHandler}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
        id="nextaaa"
        style={{ transform: "rotate(180deg)" }}
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
  const customNextArrow = (clickHandler: () => void) => (
    <div
      className={styles["customNextArrow"]}
      style={{
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        width: 30,
        right: 0,
        height: "30",
        cursor: "pointer",
        display:
          currentSlide === propertyDetails.PropertyInfoImages.images.length - 1
            ? "none"
            : "block",
      }}
      onClick={clickHandler}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
  console.log("property details ", propertyDetails);

  return (
    <Container
      fluid
      id={"propertydetails"}
      className={styles["property-details"]}
      ref={topRef}
    >
      <Row className="align-items-center">
        <Col md={9} lg={5}>
          <h3>{propertyDetails?.PropName}</h3>
        </Col>
        <Col md={3} lg={3} className="justify-content-end d-flex">
          <Button
            className={` align-items-center justify-content-center ${styles["btn-outline"]} ${styles["share-btn"]}`}
            onClick={() => {
              window.open(
                `https://www.furnishedfinder.com/property/${propertyId}#shareModal`,
                "_blank"
              );
            }}
          >
            <FiSend className="me-1" />
            Share
          </Button>
        </Col>
        <Col lg={3}></Col>
      </Row>
      <Row>
        <Col sm={12} lg={8}>
          <div className="img-slider position-relative">
            <Carousel
              showStatus={false}
              className="carousel-update"
              renderArrowPrev={customPrevArrow}
              renderArrowNext={customNextArrow}
              onChange={handleSlideChange}
            >
              {propertyDetails &&
                propertyDetails?.PropertyInfoImages?.images.map(
                  (image: any) => {
                    return (
                      <div
                        onClick={(e) => {
                          setImgModal(true);
                        }}
                      >
                        <Image src={image.url} alt="" />
                      </div>
                    );
                  }
                )}
            </Carousel>
            <p className="custom-legend">
              {PropAvailableDate <= today
                ? "Available Now"
                : `Available from ${moment(
                    PropAvailableDate.toISOString()
                  ).format("MMM Do")}`}
            </p>
          </div>

          <Badge bg="" className={styles["furnished-badge"]}>
            {propertyDetails?.IsFurnishedProperty ? "Furnished" : "Unfurnished"}
          </Badge>
          <Badge bg="" className={styles["property-badge"]}>
{          /* eslint-disable-next-line*/ }
            {propertyDetails?.PropertyType?.replace(/[\(\)]/g, "")}
          </Badge>

          <h6 className="mt-3">
            <GrLocationPin />
            {propertyDetails?.Address?.split(",").slice(0, -1).join(",")}{" "}
            <span className={styles["link-data"]} onClick={handleMapShow}>
              see location
            </span>
          </h6>

          <Anchor targetOffset={targetOffset}>
            <Link href="#description" title="Description" />
            <Link href="#property-overview" title="Property Overview" />
            <Link href="#amenities" title="Amenities" />
            <Link href="#check-availability" title="Check Availability" />
            <Link href="#reviews" title="Reviews" />
          </Anchor>

          <div id="description" className="space-top">
            <h4 className="primary-color fw-400">Description</h4>
            {readMore ? (
              propertyDetails?.PropertyDescription?.split("<br />").map(
                (data: any) => {
                  return <p>{data}</p>;
                }
              )
            ) : (
              <p>
                {propertyDetails?.PropertyDescription?.split("<br />")
                  .join(" ")
                  .slice(0, 150)}
                ...
              </p>
            )}
            <span
              className={styles["link-data"]}
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {readMore ? "Show less" : "Show full description"}
            </span>
          </div>

          <div id="property-overview" className="space-top">
            <h4>Property Overview</h4>
            <div className={styles["property-table"]}>
              <Table size="md">
                <tbody className={styles["table-body"]}>
                  <tr>
                    <td className="w-50 text-grey">Type</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.PropertyType}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Unit</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.Unit}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Parking places</td>
                    <td className="w-50 primary-color">2</td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Pets allowed</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.IsPetFriendly
                        ? propertyDetails?.PetInfo
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Minimum term</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.MinTerm}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Utilities included</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.Features?.IsUtilityIncluded
                        ? propertyDetails.Features.UtilityText
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Laundry</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.Features?.IsLaundaryIncluded
                        ? propertyDetails.Features.LaundryText
                        : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Unit type</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.UnitType}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50 text-grey">Entrance type</td>
                    <td className="w-50 primary-color">
                      {propertyDetails?.EntranceType}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div id="amenities" className="space-top">
            <h4>Amenities</h4>
            <Row style={{ flexWrap: "wrap" }}>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Air Conditioning")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={AirConditioner}
                  />
                </span>{" "}
                Air Conditioning
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Microwave")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Microwave}
                  />
                </span>{" "}
                Microwave
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Features?.IsLaundaryIncluded
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Laundry}
                  />
                </span>{" "}
                Laundry
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("WiFi")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Wifi}
                  />
                </span>{" "}
                Wifi
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Gym")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={SharedGym}
                  />
                </span>{" "}
                Shared Gym
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Housekeeping")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={HouseCleaning}
                  />
                </span>{" "}
                Housekeeping
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Balcony")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Balcony}
                  />
                </span>{" "}
                Balcony
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Parking")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Parking}
                  />
                </span>{" "}
                Parking
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Hair dryer")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={HairDryer}
                  />
                </span>{" "}
                Hair Dryer
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Heating")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Heater}
                  />
                </span>{" "}
                Heating
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Dishwasher")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Dishwasher}
                  />
                </span>{" "}
                Dishwasher
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Hottub")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt="hot-tub"
                    src={Hottub}
                  />
                </span>{" "}
                Hot tub
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Security")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Security}
                  />
                </span>{" "}
                Security
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Utensils")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Utensils}
                  />
                </span>{" "}
                Utensils
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Iron")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Iron}
                  />
                </span>{" "}
                Iron
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Alarm")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={AlarmBell}
                  />
                </span>{" "}
                Alarm bell
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes(
                    "Pets live on this property"
                  )
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={
                      propertyDetails?.Accommodation?.includes(
                        "Pets live on this property"
                      )
                        ? PetNotAllowed
                        : PetFriendly
                    }
                  />
                </span>{" "}
                {propertyDetails?.Accommodation?.includes(
                  "Pets live on this property"
                )
                  ? "Pet not allowed"
                  : "Pet Friendly"}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("First Aid Kit")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={FirstAid}
                  />
                </span>{" "}
                First Aid
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Non-smoking")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={NoSmoking}
                  />
                </span>{" "}
                No smoking
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Elevator")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Elevator}
                  />
                </span>{" "}
                Elevator
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Dining area")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={DiningArea}
                  />
                </span>{" "}
                Dining area
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Book shelf")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={BookShelf}
                  />
                </span>{" "}
                Book shelf
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Smoke Detector")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={SmokingDetector}
                  />
                </span>{" "}
                Smoking detector{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("House cleaning")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={HouseCleaning}
                  />
                </span>{" "}
                House cleaning{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Stove oven")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={StoveOven}
                  />
                </span>{" "}
                Stove and oven{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Computer table")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={ComputerTable}
                  />
                </span>{" "}
                Computer table{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Cupboard")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Cupboard}
                  />
                </span>{" "}
                Cupboard{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Swimming pool")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={SwimmingPool}
                  />
                </span>{" "}
                Swimming pool{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Storage")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Storage}
                  />
                </span>{" "}
                Storage{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes(
                    "Full-size ironing board"
                  )
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={IronBoard}
                  />
                </span>{" "}
                Iron board{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Full-size fridge")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Refridgerator}
                  />
                </span>{" "}
                Refridgerator{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Features?.IsFurnitureIncluded
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  {" "}
                  <Image
                    alt=""
                    className={`${styles["amenities-img"]}`}
                    src={Sofa}
                  />
                </span>{" "}
                Sofa{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Essentials")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={ElectricKettle}
                  />
                </span>{" "}
                Electric kettle{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("Stovetop")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Stove}
                  />
                </span>{" "}
                Stove{" "}
              </Col>
              <Col
                md={4}
                sm={6}
                xs={6}
                className={`align-items-center text-grey`}
                style={
                  propertyDetails?.Accommodation?.includes("TV")
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {" "}
                <span className={`${styles["amenities-sp"]} m-2`}>
                  <Image
                    className={`${styles["amenities-img"]}`}
                    alt=""
                    src={Monitor}
                  />
                </span>{" "}
                Television{" "}
              </Col>
            </Row>
          </div>

          <div id="check-availability" className="space-top">
            <Row className="align-items-center">
              <Col sm={6}>
                <h4>Check availability</h4>
              </Col>
              <Col sm={6}>
                <span className="text-grey">
                  Available from{" "}
                  {PropAvailableDate < today
                    ? moment(today).format("Do MMMM, YYYY")
                    : moment(PropAvailableDate).format("Do MMMM, YYYY")}
                </span>
              </Col>
            </Row>

            <DayPicker
              mode="range"
              className="pd-calendar"
              numberOfMonths={screenWidth > 900 ? 2 : 1}
              pagedNavigation
              disabled={{
                before: PropAvailableDate > today ? PropAvailableDate : today,
              }}
            />
          </div>

          <div className="mb-5">
            <Row id="reviews" className="space-top">
              <Col className={`align-items-center d-flex`}>
                <MdStar className={`${styles["content-heading-icon"]}`} />
                <span
                  className={`${styles["content-heading"]} border-end primary-color mr-2 pr-2`}
                >
                  {propertyDetails?.Ratings?.AverageStarRating}{" "}
                </span>
                <a
                  className={`${styles["content-heading"]} primary-color ml-5 ${styles["link-data"]} `}
                  href={`https://www.furnishedfinder.com/property/${propertyId}#reviews`}
                  target="_blank"
                  rel="noreferrer"
                >
                  (
                  {propertyDetails?.Ratings?.TotalReviewsCount
                    ? propertyDetails?.Ratings?.TotalReviewsCount
                    : 0}{" "}
                  reviews)
                </a>
              </Col>
              <Col className={`justify-content-end d-flex`}>
                <Button
                className={`${styles["review-btn"]} `}
                  onClick={() => {
                    window.open(
                      `https://www.furnishedfinder.com/property/${propertyId}`,
                      "_blank"
                    );
                  }}
                >
                  + Add review
                </Button>
              </Col>
            </Row>
            <Row style={{ flexWrap: "wrap" }} className="mt-3">
              {propertyDetails?.Ratings?.PropertyReview?.map(
                (rating: any, index: number) => {
                  return (
                    <Col md={6} key={index}>
                      <div>
                        <Row>
                          <Col md={2} sm={2}>
                            <div>
                              <Image
                                alt=""
                                src={rating?.ImagePath}
                                className={styles["profile-wrapper"]}
                              />
                            </div>
                          </Col>
                          <Col md={8} sm={8}>
                            <div className="fs-3">{rating?.Name}</div>
                            <div>
                              <span className="text-grey">
                                {rating?.SubmissionDate}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <title>{rating.title}</title>
                        <p className="mt-1">{rating?.ReviewText}</p>
                      </div>
                    </Col>
                  );
                }
              )}
            </Row>
            <a
              href={`https://www.furnishedfinder.com/property/${propertyId}#reviews`}
              className={styles["link-data"]}
              target="_blank"
              rel="noreferrer"
            >
              Show all reviews
            </a>
          </div>
        </Col>
        <Col sm={12} lg={4}>
          <Card className={`${styles["border-around"]} mb-3`}>
            <Card.Body>
              <div className="justify-content-between d-flex align-items-center">
                <p>Property Manager</p>
                <p
                  className={styles["link-data"]}
                  onClick={() => {
                    window.open(
                      `https://www.furnishedfinder.com/members/profile?u=${propertyDetails?.PropertyManager}`,
                      "_blank"
                    );
                  }}
                >
                  View Profile{" "}
                </p>
              </div>

              <Button
                className="w-100 mb-2"
                onClick={() => {
                  window.open(
                    `https://www.furnishedfinder.com/property/${propertyId}#contactModal`,
                    "_blank"
                  );
                }}
              >
                View Phone Number
              </Button>
              <Button
                className="btn-outline w-100"
                onClick={() => {
                  window.open(
                    `https://www.furnishedfinder.com/property/${propertyId}?sendOwnerMessageModal=true`,
                    "_blank"
                  );
                }}
              >
                Send Message
              </Button>
            </Card.Body>
          </Card>

          <Card className={`${styles["border-around"]} mb-3 primary-color`}>
            <Card.Body>
              <div className="align-items-center justify-content-between d-flex mb-0">
                <p
                  className={`primary-color fs-4 ${styles["price-list"]} mb-0`}
                >
                  {propertyDetails?.PriceText?.split("/")[0]}
                </p>
                <p className={`${styles["rating-text"]} mt-1 mb-0`}>
                  <span>
                    <MdStar
                      className={`${styles["rating-icon"]} `}
                      style={
                        propertyDetails?.Ratings?.AverageStarRating
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    />
                  </span>
                  {propertyDetails?.Ratings?.AverageStarRating
                    ? Math.round(
                        propertyDetails?.Ratings?.AverageStarRating * 10
                      ) / 10
                    : "No rating"}
                </p>
              </div>
              <hr className="primary-color my-2" />
              <Form>
                {/* <Row>
                                    <Col>
                                        <Form.Label>Minimum term</Form.Label>

                                    </Col>
                                    <Col className='justify-content-end d-flex'>
                                        <Form.Label>{propertyDetails?.MinTermDisplay}</Form.Label>

                                    </Col>
                                </Row> */}

                {propertyDetails?.PropertyFees &&
                propertyDetails?.PropertyFees?.length ? (
                  <Form.Label>Price</Form.Label>
                ) : (
                  <></>
                )}
                <div className="mb-2">
                  {propertyDetails?.PropertyFees &&
                    propertyDetails?.PropertyFees?.map(
                      (fee: any, index: number) => {
                        return (
                          <div className={styles["price-details"]} key={index}>
                            <span>{fee.Type}</span>
                            <span>{fee.Fees.replace(/\s*-\s*/, "")}</span>
                          </div>
                        );
                      }
                    )}
                </div>
                <Button
                  className="w-100"
                  onClick={() => {
                    window.open(
                      `https://www.furnishedfinder.com/property/${propertyId}#MessageModal`,
                      "_blank"
                    );
                  }}
                >
                  Booking Request
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Card className="primary-color">
            <Card.Body>
              <Row>
                <Col sm={6}>
                  <h6 className="primary-color">Location</h6>
                </Col>
                {/* <Col sm={6}><span className='text-grey'>{propertyDetails.Address}</span></Col> */}
                <div className="mapDetails">
                  <Image src={MapImg} alt="Map" style={{ width: "100%" }} />
                  <Button className="centered" onClick={handleMapShow}>
                    Show Map
                  </Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className={styles["scroll-wrap"]}>
        <div
          onClick={executeScroll}
          className={styles["scroll-icon"]}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>

      {/* phone modal */}
      <Modal show={showPhoneNumber} onHide={handlePhoneClose}>
        <Modal.Header closeButton>
          <Modal.Title>contact details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <fieldset disabled>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="password"
                  value={propertyDetails?.ContactDetails?.PropertyEmail}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="password" value="(+1) XXX XXX XXXX" />

                <FormControl.Feedback>
                  <MdOutlineContentCopy />
                </FormControl.Feedback>
                <Form.Text>{propertyDetails?.ContactDetails?.Phone}</Form.Text>
              </Form.Group>
            </fieldset>
          </Form>
        </Modal.Body>
      </Modal>
      {/* enquiry modal */}
      <Modal show={showEnquiry} onHide={handleEnquiryClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send your enquiry </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3 primary-color">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="" />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="" />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3 primary-color">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control />
            </Form.Group>

            <Form.Group className="mb-3 primary-color">
              <Form.Label>Your message</Form.Label>
              <Form.Control as="textarea" rows={4} />
            </Form.Group>

            <Button className="w-100">Send message</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showMap} onHide={handleMapClose}>
        <Modal.Body>
          {!isLoaded ? (
            <div>Loading...</div>
          ) : (
            <DetailMap
              markerid={propertyId}
              name={propertyDetails?.PropName}
              image={propertyDetails?.ImageURL}
              address={propertyDetails?.Address}
              lat={parseFloat(propertyDetails?.Latitude)}
              lng={parseFloat(propertyDetails?.Longitude)}
              zoom={13}
            />
          )}
        </Modal.Body>
      </Modal>
      {imgModal && (
        <ImageModal
          currentSlide={currentSlide}
          propertyDetails={propertyDetails}
          setImgModal={setImgModal}
          handleSlideChange={handleSlideChange}
        />
      )}
    </Container>
  );
};
export default PropertyDetails;
